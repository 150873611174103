import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import formation from '../assets/img/formation.jpeg';
import Footer from './footer.js';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

const defaultTheme = createTheme();

export default function Formation() {
    return (
        <div>
            <Box
                sx={{
                    backgroundImage: `url(public/assets/img/fond.jpg)`,
                    backgroundSize: 'cover',
                    minHeight: 'calc(100vh - 64px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" component="h1" color="inherit" style={{ marginBottom: '20px', textAlign: 'left' }}>
                            Formation sur la sécurité au travail en hauteur
                        </Typography>
                        <Typography variant="body1" color="inherit" style={{ textAlign: 'justify' }}>
                            Nous proposons des formations complètes sur la sécurité au <b>travail en hauteur conformes aux normes internationales.</b> Que vous soyez une personne ou une entreprise, notre programme de formation est conçu pour vous fournir les compétences nécessaires pour travailler en toute sécurité en hauteur, en utilisant des équipements de protection individuelle tels que les harnais de sécurité.
                        </Typography>
                        <Typography variant="body1" color="inherit" style={{ textAlign: 'justify', marginTop: '20px' }}>
                            Nos formateurs expérimentés vous guideront à travers des cours pratiques et théoriques, en mettant l'accent sur les bonnes pratiques, les procédures de sécurité et l'utilisation correcte des équipements. À la fin de la formation, vous serez équipé pour effectuer des travaux en hauteur en toute confiance, tout en respectant les normes de sécurité les plus élevées.
                        </Typography>

                        <Button variant="contained" color="primary" style={{ marginRight: '10px', marginTop: '20px' }}>
                            <PersonIcon style={{ marginRight: '5px' }} /> S'inscrire
                        </Button>

                        {/* <Button
                            variant="contained"
                            style={{
                                marginTop: '20px',
                                color: '#fff',
                                backgroundColor: '#87CEEB',
                                borderColor: '#87CEEB',
                            }}
                        >
                            <BusinessIcon style={{ marginRight: '5px' }} /> S'inscrire pour notre société
                        </Button> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={formation} alt="Formation" style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)', marginBottom: '20px' }} />
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </div>
    );
}
