import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import heliosLogo from '../assets/img/logo_clients_partner/logo_Helios.png';
import orangeLogo from '../assets/img/logo_clients_partner/Orange_logo.png';
import airtelLogo from '../assets/img/logo_clients_partner/airtelLogo_R.png';
import telmaLogo from '../assets/img/logo_clients_partner/TelmaLogo.png';
import gravity from '../assets/img/Gear-Logo-White.png';
import towerOfAfricaLogo from '../assets/img/logo_clients_partner/logo_scroll.png.webp';
import '../css/NosClients.css';

// const NosClients = () => {
//     return (
//         <div className="nos-clients">
//             <h2>Nos Clients potentiels</h2>
//             <Carousel
//                 showArrows={true}
//                 showStatus={false}
//                 showThumbs={false}
//                 infiniteLoop={true}
//                 autoPlay={true}
//                 interval={3000}
//             >
//                 <div>
//                     <img src={heliosLogo} alt="Helios" className="client-logo" />
//                 </div>

//                 <div>
//                     <img src={airtelLogo} alt="Airtel" className="client-logo" />
//                 </div>

//                 <div>
//                     <img src={towerOfAfricaLogo} alt="Tower Of Africa" className="client-logo" />
//                 </div>

//                 <div>
//                     <img src={orangeLogo} alt="Orange" className="client-logo" />
//                 </div>

//             </Carousel>
//         </div>
//     );
// }


const PermanentLogos = () => {
    return (
        <div className="permanent-logos">
            <img src={heliosLogo} alt="Helios" className="client-logo helios-logo" style={{ width: '200px', margin: '5%' }} />

            <img src={towerOfAfricaLogo} alt="Tower Of Africa" className="client-logo tower-logo" style={{ width: '200px', margin: '5%' }} />
        </div>
    );
}

const CarouselLogos = () => {
    return (
        <div className="carousel-logos">
            <Carousel
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                showIndicators={false}
                emulateTouch={true}
                swipeable={true}
                centerMode={true}
                centerSlidePercentage={83.3} // Pour afficher 3 éléments à la fois
                slidesToScroll={1} // Faites défiler un logo à la fois
            >
                {/* <img src={orangeLogo} alt="Orange" className="client-logo orange-logo" /> */}
                <img src={airtelLogo} alt="Airtel" className="client-logo" />
                <img src={telmaLogo} alt="Telma" className="client-logo" />
            </Carousel>
        </div>
    );
}

const Gravity = () => {
    return (
        <div>
            <img src={gravity} alt="Orange" className="gravity" style={{width:'50%'}} />

        </div>
    );
}

const NosClients = () => {
    return (
        <div className="nos-clients">
            <div style={{ margin: '3%' }}>
                <h2>Ils nous ont fait confiance !</h2>
                <PermanentLogos />
            </div>

            <div style={{ margin: '5%' }}>
                <h3>Nos Clients potentiels</h3>
                <CarouselLogos />
            </div>

            <div style={{ margin: '5%' }}>
                <h2>Partenaire international </h2>
                <Gravity />
            </div>

        </div>
    );
}

export default NosClients;