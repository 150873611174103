import React from 'react';
import { Grid, Typography, Box, Container, Paper } from '@mui/material';
import Footer from './footer';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsIcon from '@mui/icons-material/Settings';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import constructionSiteImg from '../assets/img/WhatsApp Image 2024-02-19 at 16.53.50 (1).jpeg';
import maintenanceImg from '../assets/img/WhatsApp Image 2024-02-19 at 16.53.50 (2).jpeg';
import reinforcementImg from '../assets/img/WhatsApp Image 2024-02-19 at 16.53.50 (3).jpeg';

const serviceItems = [
    {
        title: 'Construction Site Télécommunication',
        description: 'En 2023, GBL a réalisé une nouvelle construction de 40 sites, incluant la mise en service de la partie Énergie. Une large expérience car les sites se trouvaient dans des zones reculées avec un accès très difficile. Nos expériences nous ont permis de faciliter les chocs. GBL a mis en place un système efficace au niveau de la logistique.',
        strengths: ['Qualité', 'Efficacité', 'Réactivité'],
        img: constructionSiteImg,
        icon: <ConstructionIcon fontSize="large" />,
        anchor: 'construction-site'
    },
    {
        title: 'Maintenance Préventive',
        description: 'Assurer la pérennité des ouvrages en entier, identification des boulons rouillés et remplacement, traitement des éléments en dégradation. En 2023, GBL a effectué plus de 50 sites, selon la demande du promoteur. La réalisation s’effectue avec des matériels adéquats.',
        img: maintenanceImg,
        icon: <SettingsIcon fontSize="large" />,
        anchor: 'maintenance'
    },
    {
        title: 'Renforcement du Pylône',
        description: 'Renforcer le pylône en effectuant le renforcement des membrures et toutes les pièces secondaires du pylône, le remplacement et la création des nouveaux connecteurs, le remplacement des boulons, et le resserrage et les travaux de finition à l’aide des peintures. En 2023, GBL a effectué 21 renforcements.',
        objectives: ['Sécuriser la surcharge', 'Augmenter la portance du pylône'],
        means: 'GBL utilise des équipements performants, fiables et accessibles à toutes les hauteurs du pylône.',
        img: reinforcementImg,
        icon: <SignalCellularAltIcon fontSize="large" />,
        anchor: 'reinforcement'
    }
];

function Service() {
    return (
        <div>
            <Container maxWidth="lg" style={{ paddingTop: '50px' }}>
                {serviceItems.map((item, index) => (
                    <Paper key={index} elevation={3} style={{ padding: '30px', marginBottom: '50px' }}>
                        <Grid container spacing={4} alignItems="center" id={item.anchor}>
                            <Grid item xs={12} md={6} order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}>
                                <img src={item.img} alt={item.title} style={{ width: '100%', borderRadius: '10px' }} />
                            </Grid>
                            <Grid item xs={12} md={6} order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}>
                                <Typography variant="h4" gutterBottom style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {item.icon} {item.title}
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {item.description}
                                </Typography>
                                {item.strengths && (
                                    <Box mb={2}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Points forts :
                                        </Typography>
                                        <Typography variant="body1" paragraph>
                                            {item.strengths.join(', ')}
                                        </Typography>
                                    </Box>
                                )}
                                {item.objectives && (
                                    <Box mb={2}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Objectifs :
                                        </Typography>
                                        {item.objectives.map((objective, index) => (
                                            <Typography key={index} variant="body1" paragraph>
                                                - {objective}
                                            </Typography>
                                        ))}
                                    </Box>
                                )}
                                {item.means && (
                                    <Box mb={2}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Moyens :
                                        </Typography>
                                        <Typography variant="body1" paragraph>
                                            {item.means}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </Container>
            <Footer />
        </div>
    );
}

export default Service;
