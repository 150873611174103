import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
// import MapComponent from './MapComponent.js';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
// import GlobalStyles from '@mui/material/GlobalStyles';
// import Container from '@mui/material/Container';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardMedia, Box, Button } from '@mui/material';

import Grid from '@mui/material/Grid';
import logo from '../assets/Logotype/300ppi/Blue Logo line.png';
import fond from '../assets/img/IMG_3707.jpeg';
import image1 from '../assets/img/IMG_4414.jpg';
import '../css/Home.css';
import NosClients from '../pages/NosClients.js';
import Footer from './footer.js';
import CardGrid from "./CardGrid.js";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import StarIcon from '@mui/icons-material/Star';



const defaultTheme = createTheme();


export default function Home() {

    // Définir un tableau d'images pour le carousel


    // function used for the animation
    const titleRef = React.useRef();
    const logoRef = React.useRef();

    const { ref: titleInViewRef, inView: titleInView } = useInView({
        threshold: 0.5,
    });

    const { ref: logoInViewRef, inView: logoInView } = useInView({
        threshold: 0.5,
    });
    // end function used for the animation

    const [flipped, setFlipped] = useState(false);

    const flip = () => {
        setFlipped(!flipped);
    };

    return (
        <div>
            <div className='landing' style={{ position: 'relative' }}>
                {/* Ajout des icônes sociaux */}
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '5%',
                    zIndex: '1',
                    transform: 'scaleX(-1)',
                    marginTop: '15rem',

                }}>
                    <FacebookIcon style={{ fontSize: 30, color: '#fff', marginRight: '1rem' }} />
                    <LinkedInIcon style={{ fontSize: 30, color: '#fff', marginRight: '1rem' }} />
                    <EmailIcon style={{ fontSize: 30, color: '#fff' }} />
                </div>

                <div style={{
                    float: 'left',
                    fontSize: 12, color: '#fff', position: 'absolute',
                    top: '50%', transform: 'translateY(-50%)', right: '5%', zIndex: '1',
                    transform: 'scaleX(-1)', marginTop: '15rem', marginRight: '70rem',

                }}>
                    <Typography style={{ textAlign: 'justify' }}>
                        Nous travaillons <br />
                        dans le domaine de <b style={{ color: "yellow" }}>Telecommunication</b> <br /> spécialisé dans <b style={{ color: "yellow" }}>Civils works</b> <br /> et <b style={{ color: "yellow" }}>Energy</b>
                    </Typography>
                </div>

                <div class="landing-card">
                    <div class="landing__content">
                        <h1 className={`title-landing ${titleInView ? 'fade-in' : ''}`} ref={titleInViewRef} style={{ color: '#fff', zIndex: '0' }}>  Telecom<br /> <hr />
                            Energy & civils works</h1>

                    </div>
                </div>

            </div>


            <Grid container spacing={2} className='about-us'>

                <Grid item xs={12} md={6} >
                    <h2 style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 'normal', alignItems: 'center', textAlign: 'center', color: "#2665ae", fontSize: '2rem' }}>
                        Votre partenaire de confiance dans le domaine des télécommunications et de la construction.
                    </h2>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                    >
                        <Card elevation={5} style={{ height: 'auto', margin: '10% 0%', borderRadius: "12px" }}>
                            <CardContent className="body-us">
                                <h2 style={{ alignItems: 'center', textAlign: 'center' }}>
                                    <AccountCircleIcon style={{ color: '#2665ae', marginRight: '1rem', fontSize: '2rem' }} />
                                </h2>
                                <Typography variant="body2" color="text.secondary" style={{ fontSize: '1.2em' }}>
                                    Fondée en 2019, notre entreprise a débuté́ ses activités dès l’an 2000, accumulant ainsi deux décennies d’éxpérience précieuses dans la construction et renforcement des pylônes , énergies et projets en génie civils.
                                </Typography>
                            </CardContent>
                        </Card>
                    </motion.div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <img src={logo} alt="Logo" style={{ height: '15%', width: 'auto' }} />
                </Grid>
                {/* <Grid item xs={12} md={6} className={`logo-container ${logoInView ? 'fade-in-logo' : ''}`} ref={logoInViewRef}>
                    <img src={logo} alt="Logo" style={{ height: '15%', width: 'auto' }} />
                </Grid> */}

                <div style={{ marginTop: 'calc(50vh - 2300px)', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <CardGrid />
                    
                </div>

                {/* Activity - vision */}
                <Grid container spacing={2} style={{ marginTop: '-110%' }}>
                    <Grid item xs={12} md={6} style={{ padding: '2%', textAlign: 'justify' }}>
                        <h2 style={{ display: 'flex', alignItems: 'center' }}>
                            <BusinessIcon style={{ color: '#2665ae', marginRight: '1rem' }} /> Nos Activités
                        </h2>
                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '1em' }}>
                            GBL excelle dans la mise en œuvre des <b>infrastructures télécom, construction des pylônes</b> et divers <b>travaux en génie civil</b>, démontrant une expertise pointue dans le domaine de la télécommunication
                        </Typography>

                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '1em' }}>
                            Notre éligibilité́ à travailler en hauteur et dans les zones reculées témoigne de notre engagement à relever des défis varies pour répondre aux besoins de nos clients.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: '2%', textAlign: 'justify' }}>
                        <h2 style={{ display: 'flex', alignItems: 'center' }}>
                            <VisibilityIcon style={{ color: '#2665ae', marginRight: '1rem' }} /> Vision
                        </h2>
                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '1em' }}>
                            La vision de GBL est axée sur le<b> développement continu</b> et <b>l’amélioration constante</b> de la qualité́ de service. Nous aspirons à être des pionniers dans notre domaine, en offrant des solutions innovantes et sécurisées qui répondent aux normes les plus enlevées de l’industrie.
                        </Typography>
                    </Grid>
                </Grid>

                {/* Engagement - Mission */}

                <Grid container spacing={2} style={{ marginTop: '-95%' }}>
                    <Grid item xs={12} md={6} style={{ padding: '2%', textAlign: 'justify' }}>
                        <h2 style={{ display: 'flex', alignItems: 'center' }}>
                            <VerifiedUserIcon style={{ color: '#2665ae', marginRight: '1rem' }} /> Engagement envers la
                            Sécurité́ avec Gravity International

                        </h2>
                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '1em' }}>
                            Nous accordons une priorité absolue à <b>la sécurité au sein de nos opérations.</b> En partenariat avec <em><b>Gravity</b></em>, une certification renommée, nous nous engageons à assurer un travail sécurisé́, garantissant ainsi la protection de notre équipe et la qualité irréprochable de nos projets.
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '1em' }}>
                            Notre éligibilité́ à travailler en hauteur et dans les zones reculées témoigne de notre engagement à relever des défis varies pour répondre aux besoins de nos clients.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: '2%', textAlign: 'justify' }}>
                        <h2 style={{ display: 'flex', alignItems: 'center' }}>
                            <AssignmentIcon style={{ color: '#2665ae', marginRight: '1rem' }} /> Mission
                        </h2>
                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '1em' }}>
                            Notre mission première est de <b>respecter scrupuleusement nos engagements envers nos clients.</b>  Nous nous efforçons constamment d’offrir des solutions fiables et de qualité́, tout en maintenant une relation transparente et durable avec nos partenaires.
                        </Typography>
                    </Grid>
                </Grid>


                {/* Carte */}
                <motion.div
                    whileHover={{ scale: 1.1 }}
                >
                    <Grid item xs={12} style={{ marginTop: '-50rem', textAlign: 'justify', marginRight: '20%' }}>
                        <Card sx={{ display: 'flex', borderRadius: '12px', overflow: 'hidden', boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)', width: '85vw', height: 'calc(100vh -50px)' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <h2 style={{ display: 'flex', alignItems: 'center', color: '#2665ae', marginBottom: '1rem', marginTop: '20px' }}>
                                        <EmojiObjectsIcon style={{ marginRight: '1rem' }} /> Objectifs
                                    </h2>
                                    <Typography variant="body2" color="text.secondary" style={{ fontSize: '1em' }}>
                                        Nos objectifs sont clairs : <b>être leader dans le domaine.</b> Nous visons l’excellence opérationnelle et la satisfaction totale de nos clients.
                                    </Typography>

                                    <br />


                                    <h2 style={{ display: 'flex', alignItems: 'center', color: '#2665ae', marginBottom: '1rem' }}>
                                        <StarIcon style={{ marginRight: '1rem' }} /> Valeurs
                                    </h2>
                                    <Typography variant="body2" color="text.secondary" style={{ fontSize: '1em' }}>
                                        Les valeurs fondamentales de GBL sont <b>l’efficacité, la productivité́</b> et<b> la performance.</b> <br />
                                        Ces principes guident notre travail quotidien, assurant une approche professionnelle et axée sur les résultats dans tout ce que nous entreprenons.
                                    </Typography>

                                    <Button variant="contained" color="primary" style={{ marginTop: '20px', marginLeft: '10px' }}>
                                        Voir plus
                                    </Button>
                                </CardContent>
                            </Box>
                            <CardMedia
                                component="img"
                                sx={{ width: '70vw', height: 'calc(50vh - 10px)' }}
                                image={fond}
                                alt="TExt"
                            />
                        </Card>
                    </Grid>
                </motion.div>



                <div style={{ marginTop: 'calc(50vh - 800px)', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <NosClients />
                </div>

                {/*  */}


            </Grid>
            <Footer />
        </div>
    );
}
