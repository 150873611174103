import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';

function Footer() {
    return (
        <footer style={{ backgroundColor: '#15202B', color: '#fff', padding: '50px 0' }}>
            <Grid container spacing={3} justifyContent="center">
                {/* A propos de nous */}
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6">A propos de nous</Typography>
                    <Typography variant="body2" style={{ marginBottom: '15px' }}>Notre vision</Typography>
                    <Typography variant="body2" style={{ marginBottom: '15px' }}>Nos Activités</Typography>
                    <Typography variant="body2" style={{ marginBottom: '15px' }}>Nos engagements</Typography>
                </Grid>

                {/* Contact */}
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6">Contact</Typography>
                    <Typography variant="body2" style={{ marginBottom: '15px' }}>Email: contact@gbline.mg</Typography>
                    <Typography variant="body2" style={{ marginBottom: '15px' }}> gblinecontact@gmail.com</Typography>
                    <Typography variant="body2">Téléphone: +261 34 66 457 21</Typography>
                </Grid>

                {/* Siège */}
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6">Siège</Typography>
                    <Typography variant="body2">Adresse:Lot 85 CI BIS Behitsy Ambohimangakely, Antananarivo 101</Typography>
                </Grid>

                {/* Réseaux Sociaux */}
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6">Réseaux Sociaux</Typography>
                    <IconButton>
                        <FacebookIcon />
                    </IconButton>
                    <IconButton>
                        <TwitterIcon />
                    </IconButton>
                    <IconButton>
                        <LinkedInIcon />
                    </IconButton>
                    <IconButton>
                        <InstagramIcon />
                    </IconButton>
                    <IconButton>
                        <MailIcon />
                    </IconButton>
                </Grid>
            </Grid>

            {/* Copyright */}
            <div style={{ backgroundColor: '#1B2836', padding: '20px 0', textAlign: 'center' }}>
                <Typography variant="body2">© 2024 GBL. Tous droits réservés.</Typography>
            </div>
        </footer>
    );
}

export default Footer;
