import React from 'react';
import { Card, CardContent, Typography, Button, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom'; // Importez le composant Link de React Router
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsIcon from '@mui/icons-material/Settings';
import TowerIcon from '@mui/icons-material/SignalCellularAlt';
import '../css/CardGrid.css'; // Assurez-vous d'avoir un fichier CSS pour les animations

function InfoCard({ id, title, content, background, buttonLabel }) {
    return (
        <Card className="infoCard" style={{ backgroundColor: background }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            {title === 'CONSTRUCTION SITE TELECOMMUNICATION' && <ConstructionIcon sx={{ fontSize: 40, color: '#fff' }} />}
                            {title === 'SITE MAINTENANCE PREVENTIVE' && <SettingsIcon sx={{ fontSize: 40, color: '#002D72' }} />}
                            {title === 'RENFORCEMENT DU PYLONE' && <TowerIcon sx={{ fontSize: 40, color: '#fff' }} />}
                            <Typography variant="h5" component="h2" sx={{ marginLeft: '10px', color: title === 'SITE MAINTENANCE PREVENTIVE' ? '#002D72' : '#fff' }}>{title}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: title === 'SITE MAINTENANCE PREVENTIVE' ? '#002D72' : '#fff' }}>{content}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" component={Link} to={`/service#${id}`} sx={{ backgroundColor: '#fff', color: '#000' }}>
                            {buttonLabel}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

function CardGrid() {
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <InfoCard
                        id="construction"
                        title="CONSTRUCTION SITE TELECOMMUNICATION"
                        content="En 2023, GBL a réalisé une nouvelle construction de 40 sites, incluant la mise en service de la partie Energie...."
                        background="#002D72"
                        buttonLabel="Learn more..."
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InfoCard
                        id="maintenance"
                        title="SITE MAINTENANCE PREVENTIVE"
                        content="Assurer la pérennité des ouvrages en entier, identification des boulons rouillés et remplacement..."
                        background="#fff"
                        buttonLabel="Learn more..."
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InfoCard
                        id="reinforcement"
                        title="RENFORCEMENT DU PYLONE"
                        content="Renforcement des membrures et toutes les pièces secondaires du pylône, Remplacement et création des nouveaux connecteurs;..."
                        background="#002D72"
                        buttonLabel="Learn more..."
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default CardGrid;
