import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import logo from '../Blue Icon.png';
import Home from '../pages/Home';
import Service from '../pages/Service';
import Formation from '../pages/Formation';
import Products from '../pages/ProductFile';
import '../css/Header.css';

const defaultTheme = createTheme();

export default function Header() {
    const [currentPage, setCurrentPage] = React.useState('home');
    const [transparentBackground, setTransparentBackground] = React.useState(true);
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

    const handleMenuClick = (page) => {
        setCurrentPage(page);
        setMobileMenuOpen(false);
    };

    const renderCurrentPage = () => {
        if (currentPage === 'home') {
            return <Home />;
        } else if (currentPage === 'service') {
            return <Service />;
        } else if (currentPage === 'formation') {
            return <Formation />;
        } else if (currentPage === 'products') {
            return <Products />;
        }
    };

    React.useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop <= 10) {
                setTransparentBackground(true);
            } else {
                setTransparentBackground(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyles
                styles={{
                    ul: { margin: 0, padding: 0, listStyle: 'none' },
                    '.MuiLink-root:hover': { textDecoration: 'underline' },
                }}
            />
            <AppBar
                position="fixed"
                color={transparentBackground ? 'transparent' : 'default'}
                elevation={0}
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box component="span">
                        <img src={logo} alt="Logo" style={{ height: '100px' }} />
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Button onClick={() => setMobileMenuOpen(true)}>Menu</Button>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <nav style={{ marginLeft: 'auto' }}>
                            <Link
                                variant="button"
                                color="text.primary"
                                href="#"
                                sx={{ my: 1, mx: 1.5 }}
                                onClick={() => handleMenuClick('home')}
                            >
                                Home
                            </Link>
                            <span>|</span>
                            <Link
                                variant="button"
                                color="text.primary"
                                href="#"
                                sx={{ my: 1, mx: 1.5 }}
                                onClick={() => handleMenuClick('service')}
                            >
                                Service
                            </Link>
                            <span>|</span>
                            <Link
                                variant="button"
                                color="text.primary"
                                href="#"
                                sx={{ my: 1, mx: 1.5 }}
                                onClick={() => handleMenuClick('formation')}
                            >
                                Formation
                            </Link>
                            <span>|</span>
                            <Link variant="button" color="text.primary" href="#" sx={{ my: 1, mx: 1.5 }} onClick={() => handleMenuClick('products')}>
                                Nos produits
                            </Link>
                        </nav>
                    </Box>
                    <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                        Login
                    </Button>
                </Toolbar>
            </AppBar>
            {/* Menu for mobile */}
            <Drawer
                anchor="top"
                open={mobileMenuOpen}
                onClose={() => setMobileMenuOpen(false)}
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={() => setMobileMenuOpen(false)}
                    onKeyDown={() => setMobileMenuOpen(false)}
                >
                    <List>
                        <ListItem button onClick={() => handleMenuClick('home')}>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button onClick={() => handleMenuClick('service')}>
                            <ListItemText primary="Service" />
                        </ListItem>
                        <ListItem button onClick={() => handleMenuClick('formation')}>
                            <ListItemText primary="Formation" />
                        </ListItem>
                        <ListItem button onClick={() => handleMenuClick('products')}>
                            <ListItemText primary="Nos produits" />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            {renderCurrentPage()}
        </ThemeProvider>
    );
}
