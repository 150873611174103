import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, ShoppingBasket } from '@mui/icons-material';
import Footer from './footer.js';
import image22 from '../assets/Catalogue Gravity/100m RATCHET RESCUE KIT-1.png';
import image23 from '../assets/Catalogue Gravity/Gravity Gear Telecoms Fall Arrest kit_Titan&813Harness-1.png';
import image24 from "../assets/Catalogue Gravity/Rope Rigging Kit 3-1 Top Control with Reset Max 100 kg-1.png";

function ProductFile() {
    const [currentImage, setCurrentImage] = useState(image22); // Image actuellement affichée

    // Fonctions pour passer à l'image précédente et suivante
    const goToPreviousImage = () => {
        if (currentImage === image22) setCurrentImage(image24);
        else if (currentImage === image23) setCurrentImage(image22);
        else setCurrentImage(image23);
    };

    const goToNextImage = () => {
        if (currentImage === image22) setCurrentImage(image23);
        else if (currentImage === image23) setCurrentImage(image24);
        else setCurrentImage(image22);
    };

    // Fonction pour acheter (vous pouvez ajouter votre logique d'achat ici)
    const handleBuy = () => {
        // Ajoutez votre logique d'achat ici
        alert('Acheter');
    };

    return (
        <div>
            <Grid container spacing={3} style={{ textAlign: 'center', marginTop: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={12} md={6}>
                    <img src={currentImage} alt="Produit" style={{ width: '100%' }} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={2} style={{ marginTop: '20px' }}>
                <Grid item style={{ marginBottom: '20px' }}>
                    <Button variant="outlined" color="primary" onClick={goToPreviousImage} startIcon={<KeyboardArrowLeft />}>
                        Précédent
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="secondary" onClick={handleBuy} startIcon={<ShoppingBasket />}>
                        Acheter
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="primary" onClick={goToNextImage} endIcon={<KeyboardArrowRight />}>
                        Suivant
                    </Button>
                </Grid>
            </Grid>

            <Footer />

        </div>
    );
}

export default ProductFile;
